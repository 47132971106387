import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';
import SEO from '../components/SEO/SEO';
import { wrapper, breakpoints } from '../utils/style';

const Wrapper = styled('div')`
  ${wrapper}
  margin: 30px auto;
  ul {
    list-style:none;
    margin:0;
    padding:0;
    border-top:1px solid #CCC;
    border-bottom:1px solid #CCC;
  }
  li:nth-of-type(2n) {
    background-color:#EEE;
  }
  a {
    display: block;
    padding: 3px 10px;
    text-decoration:none;
    color:#222;
  }
  a:hover, a:active {
    color:#000;
  }
  @media (max-width: ${breakpoints.md}) {
    a {
      margin: 7px 0;
      padding: 10px;
      font-size: 16px;
    }
  }
`;

const HtmlSitemap = ({ data }) => {
  // const Posts = data.allWordpressPost.edges.map(edge =>
  //   (edge.node.acf && edge.node.acf.post_template !== 'service_post_carousel' &&
  //   <li key={edge.node.id} className='post'>
  //     <Link to={"/" + edge.node.slug + "/"} dangerouslySetInnerHTML={{ __html: edge.node.title }} />
  //   </li>)
  //   );

  const Pages = data.allWordpressPage.edges.map(edge =>
    <li key={edge.node.id} className='page'>
      {(edge.node.slug === 'home-page') ? (
        <Link to='/' dangerouslySetInnerHTML={{ __html: edge.node.title }} />

      ) : (
        <Link
          to={(edge.node.wordpress_parent > 0) ? edge.node.path : ("/" + edge.node.slug + "/")}>{edge.node.title}
        </Link>
      )}
    </li>);
  // const Categories = data.allWordpressCategory.edges.map(edge =>
  //   <li key={edge.node.id} className='category'>
  //     <Link to={edge.node.path.replace('category/', '')} dangerouslySetInnerHTML={{ __html: edge.node.name }} />
  //   </li>);
  return (
    <Wrapper>
      <SEO title='HTML sitemap'
           description='HTML sitemap for holidaypalace: holidaypalace.gclub-casino.com'/>
      <h1>HTML sitemap</h1>
      <ul>
        {Pages}
        {/*{Categories}*/}
        {/*{Posts}*/}
      </ul>
    </Wrapper>
  )
};

export default HtmlSitemap

export const pageQuery = graphql`
  query all {
    allWordpressPage(
        sort: { fields: [date] }
      ) {
      edges {
        node {
          title
          id
          slug
          path
          wordpress_parent
        }
      }
    }
  }  
`;
